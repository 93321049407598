import Typography from '@bluheadless/ui/src/atoms/typography'
import { styled } from '@mui/system'
import { Grid as MuiGrid } from '@mui/material'
import Button from '@bluheadless/ui/src/molecules/button'

const Root = styled('div')`
	display: flex;
	flex-direction: column;
`

const Title = styled(Typography)`
	margin-top: 10px;
	font-size: ${({ theme }) => theme.typography.pxToRem(18)};
`

const ImageColumn = styled('div')``

const TextColumn = styled('div')`
	display: flex;
	justify-content: space-between;
	flex-direction: column;

	${({ theme }) => theme.breakpoints.up('md')} {
		align-items: flex-start;
		bottom: 0;
	}
`

const Description = styled(Typography)`
	margin-top: 10px;
	max-width: 75%;
	line-height: 1.6;
	padding-right: 10px;
	.MuiTypography-root {
		font-size: inherit;
		line-height: inherit;
	}
`

const CtaContainer = styled(MuiGrid)`
	gap: 22px;
	margin-bottom: 0px;
	margin-top: 10px;
	flex-basis: 50%;
	text-align: left;
	justify-content: flex-start;
	${({ theme }) => theme.breakpoints.up('sm')} {
		flex-basis: auto;
		justify-content: flex-start;
	}
	${({ theme }) => theme.breakpoints.up('md')} {
		gap: 22px;
		margin-bottom: 0;
	}
`

const ButtonStyled = styled(Button)`
	font-weight: 400;
`

export { Title, Description, Root, ImageColumn, TextColumn, CtaContainer, ButtonStyled }
