import Grid from '@mui/material/Grid'
import { cx } from '@emotion/css'
import Link from '@bluheadless/ui/src/molecules/link'
import { ImageArtDirection } from '@bluheadless/ui/src/particles/art-direction'
import {
	CtaContainer,
	ImageColumn,
	Root,
	TextColumn,
	Title,
	ButtonStyled,
	Description,
} from './image-text-banner.styled'
import { useConfig } from '@bluheadless/ui-logic/src/providers/config'

const ImageTextBanner = ({
	title,
	titleProps,
	content,
	imageSrc,
	imageOriginalWidth,
	imageOriginalHeight,
	imageSources,
	imageProps,
	imageLink,
	imageLinkTarget,
	cta,
	ctaProps,
	className,
	horizontalAlign,
	...props
}) => {
	const { siteName } = useConfig()

	return (
		<Root className={cx('ImageTextBanner-root', className)} {...props}>
			<ImageColumn>
				<Link href={imageLink} target={imageLinkTarget}>
					<ImageArtDirection
						src={imageSrc}
						alt={title ? title + ' | ' + siteName : siteName}
						originalWidth={imageOriginalWidth}
						originalHeight={imageOriginalHeight}
						sources={imageSources}
						{...imageProps}
					/>
				</Link>
			</ImageColumn>
			<TextColumn>
				{title && (
					<Title variant="h3" {...titleProps} component="h4">
						{title}
					</Title>
				)}
				{content && (
					<Description component="div" variant="body1">
						{content}
					</Description>
				)}
				{cta && cta?.length > 0 && (
					<CtaContainer display="inline-flex" justifyContent={horizontalAlign} {...ctaProps}>
						{cta.map(
							({ label, ...rest }, key) =>
								label?.length > 0 && (
									<Grid item key={key}>
										<ButtonStyled {...rest}>{label}</ButtonStyled>
									</Grid>
								)
						)}
					</CtaContainer>
				)}
			</TextColumn>
		</Root>
	)
}

export default ImageTextBanner
